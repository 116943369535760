import "./Portfolio.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import React, { useEffect, useRef } from "react";

// import SiteCard from "./SiteCard";
import Card from "./Card";

const Portfolio = () => {
  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const PortfolioList = [
    {
      id: 1,
      src: "./pictures/glassico-website.webp",
      alt: "glassico-website",
      url: "https://www.glassico.ca/",
    },
    {
      id: 2,
      src: "./pictures/travel-website.webp",
      alt: "travel-website",
      url: "https://travel.elena-web.com/",
    },
    {
      id: 3,
      src: "./pictures/portfolio-website.webp",
      alt: "portfolio-website",
      url: "https://www.elena-web.com/",
    },
    {
      id: 4,
      src: "./pictures/realestate-website.webp",
      alt: "realestate-website",
      url: "https://realestate.elena-web.com/",
    },
    {
      id: 5,
      src: "./pictures/gym-website.webp",
      alt: "gym-website",
      url: "https://www.gym.elena-web.com/",
    },
    {
      id: 6,
      src: "./pictures/bakery-website.webp",
      alt: "bakery-website",
      url: "https://www.bakery.elena-web.com/",
    },
  ];

  return (
    <>
      <div className="wrapper">
        <h2 className="section_sub_title" style={{ color: "var(--colorText)" }}>
          Examples Of Web Sites:
        </h2>
        <p className="portfolio_text">
          We take pride in every project we undertake, delivering solutions that
          transcend expectations. From captivating designs to cutting-edge
          developments, our portfolio is a testament to our commitment to
          excellence.
        </p>
        <br></br>
        <br></br>
      </div>
      <div className="content">
        <div className="container">
          <Slider {...settings}>
            {PortfolioList.map((item) => (
              <div key={item.id}>
                <div className="img-body">
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <img src={item.src} alt={item.alt} />
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* <Card /> */}
    </>
  );
};

export default Portfolio;
