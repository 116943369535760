import React, { useState } from "react";
import { CiMail, CiPhone } from "react-icons/ci";
import { PiWhatsappLogoLight } from "react-icons/pi";
import "./ContactUs.css";

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch("http://localhost:8080/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage("Your request was sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });

        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      } else {
        console.error("Failed to send form data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section>
      <h1 className="contact_title">Get in touch with us</h1>
      <div className="wrapper">
        <h2>
          Have a question or need assistance?
          <br />
          Our customer support team is here to help you.
          <br />
          You can reach them at{" "}
          <a href="mailto:support@lilays.com">support@lilays.com</a>
        </h2>
        <div className="contact_card_section">
          <div className="contact_area">
            <div className="icon_background">
              <a href="tel:+16472255955">
                <CiPhone className="contact_icon" />
              </a>
            </div>
            <h2>PHONE</h2>
            <p>
              Lilays contact center: <br />
              <a
                href="https://wa.me/16472255955"
                target="_blank"
                rel="noreferrer"
              >
                +16472255955
              </a>
            </p>
            <p>Give us a call if you have any questions or need assistance.</p>
          </div>
          <div className="contact_area">
            <div className="icon_background">
              <a
                href="https://wa.me/16472255955"
                target="_blank"
                rel="noreferrer"
              >
                <PiWhatsappLogoLight className="contact_icon" />
              </a>
            </div>
            <h2>WHATSAPP</h2>
            <p>
              Lilays contact center: <br />
              <a
                href="https://wa.me/16472255955"
                target="_blank"
                rel="noreferrer"
              >
                +1 (647) 225-5955
              </a>
            </p>
            <p>For WhatsApp inquiries, click the icon above to chat with us.</p>
          </div>
          <div className="contact_area">
            <div className="icon_background">
              <a href="mailto:info@lilays.com" rel="noreferrer" target="_blank">
                <CiMail className="contact_icon" />
              </a>
            </div>
            <h2>EMAIL</h2>
            <p>
              Lilays for any questions:{" "}
              <a href="mailto:info@lilays.com">info@lilays.com</a>
            </p>
            <p>Feel free to email us for any inquiries or proposals.</p>
          </div>
        </div>
        {successMessage && (
          <div className="success_message">{successMessage}</div>
        )}

        <form
          className="contact_form"
          onSubmit={handleSubmit}
          style={{
            position: "relative",
            width: "90%",
            maxWidth: "800px",
            margin: "20px",
            padding: "40px",
            border: "1px solid var(--colorCompany)",
            borderRadius: "30px",
            zIndex: 1,
            backgroundImage: "url('/pictures/lilays-backgroung-flower.png')",
            backgroundSize: "cover",
            // backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h2 className="form_title">Contact Form</h2>
          <div className="form_group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your name.."
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form_group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your email.."
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form_group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Your phone number.."
              value={formData.phone}
              onChange={handleChange}
              required
              pattern="[0-9]*"
              inputMode="numeric"
            />
          </div>
          <div className="form_group">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder="Write the subject.."
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form_group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Write your question.."
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="submit_button">
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
