import "./Services.css";

const cardsInfo = [
  {
    title: "Custom Design",
    description:
      "Our team of skilled designers will work closely with you to develop a visually stunning and user-friendly website that reflects your brand identity.",
  },
  {
    title: "Responsive Website",
    description:
      "Your website will be built to be fully responsive, ensuring a seamless and consistent user experience across all devices.",
  },
  {
    title: "Latest Technologies",
    description:
      "Your webpage will be developed and powered by cutting-edge technologies, guaranteeing a truly state-of-the-art digital experience",
  },
  {
    title: "Security",
    description:
      "Your website will run on top-tier, secure cloud providers, adhering to the highest standards of security practices.",
  },
  {
    title: "Domain Registration",
    description:
      "We'll assist you in choosing and registering your unique domain that will help the growth of your online business.",
  },
  {
    title: "Content Management",
    description:
      "Update and manage your website content when you need, empowering you to keep your site current.",
  },
  // TODO: Complete
];

const Services: React.FC = () => {
  return (
    <section className="second_section">
      <div className="wrapper">
        <h2
          className="section_sub_title"
          id="services"
          style={{ color: "#1273b7" }}
        >
          What We Can Create For You
        </h2>
        <div className="services">
          {cardsInfo.map((c, i) => (
            <div className="card_container" key={i}>
              <div className="cards_container_title">{c.title}</div>
              <p>{c.description}</p>
            </div>
          ))}
          <div className="card_container">
            <div className="cards_container_title">SEO </div>
            <p>
              Maximize your online visibility with our search engine
              optimization services, ensuring that your website ranks well on
              search engines and attracts your target audience.
            </p>
          </div>
          <div className="card_container">
            <div className="cards_container_title">Hosting Management</div>
            <p>
              We offer reliable and secure hosting services for your website.
              Benefit from scalable hosting options to accommodate the growth of
              your website and business.
            </p>
          </div>
          <div className="card_container">
            <div className="cards_container_title">Performance Analytics</div>
            <p>
              Gain valuable insights into your website's performance with
              integrated analytics, allowing you to make informed decisions to
              enhance your online strategy.
            </p>
          </div>
          {/* Video Container */}
          <div className="video_container">
            <video controls width="600">
              <source src="/pictures/Lilays-services.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Services;
