import React from 'react';
import { MdEmail } from 'react-icons/md';
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';

interface ShareButtonProps {
    platform: 'facebook' | 'linkedin' | 'email' | 'whatsapp';
    url: string;
    title: string;

}

function ShareButton({ platform, url, title, }: ShareButtonProps) {
    let shareUrl: string = '';

    switch (platform) {
        case 'facebook':
            shareUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`;
            break;
        case 'linkedin':
            shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
            break;

        case 'email':
            shareUrl = `mailto:?subject=${encodeURIComponent(title)}&body=Article:%20${encodeURIComponent(url)}`;
            break;
        case 'whatsapp':
            shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(title)}%20${encodeURIComponent(url)}`;
            break;
        default:
            shareUrl = '';
    }

    const handleClick = () => {
        if (shareUrl) {
            window.open(shareUrl, '_blank');
        }
    };
    const icon =
        platform === 'facebook' ? (
            <FaFacebookF className="footer_icon" />
        ) : platform === 'linkedin' ? (
            <FaLinkedinIn className="footer_icon" />
        ) : platform === 'whatsapp' ? (
            <FaWhatsapp className="footer_icon" />
        ) : (
            <MdEmail className="footer_icon" />
        );

    return (
        <React.Fragment>
            {shareUrl && (
                <a
                    aria-label={`Share on ${platform}`}
                    className="social"
                    href={shareUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleClick}
                >
                    {icon}
                </a>
            )}
        </React.Fragment>
    );
}

function BlogPostShareButtons() {
    const blogPosts = [
        {
            title: 'Mastering Web Accessibility: The Complete Manual',
            url: 'https://www.lilays.com/accessibility'
        },

    ];

    return (
        <div style={{ display: 'flex', gap: '10px', justifyContent: "center" }}>
            {blogPosts.map((blogPost, index) => (
                <React.Fragment key={index}>

                    <ShareButton platform="facebook" title={blogPost.title} url={blogPost.url} />
                    <ShareButton platform="linkedin" title={blogPost.title} url={blogPost.url} />
                    <ShareButton platform="email" title={blogPost.title} url={blogPost.url} />
                    <ShareButton platform="whatsapp" title={blogPost.title} url={blogPost.url} />
                </React.Fragment>
            ))}
        </div>
    );
}

export default BlogPostShareButtons;
