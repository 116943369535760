import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./Header.css";
// import { FaPhone } from "react-icons/fa";

const Header: React.FC = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  return (
    <header className="App-header">

      <div className="logo">
        <div className="iconLogo"></div>
        <div className="company_name">Lilays</div>
      </div>


      <div
        className={`burger ${menuVisible ? "open" : ""}`}
        onClick={() => setMenuVisible(!menuVisible)}
      >
        {menuVisible ? "✕" : "☰"}
      </div>

      <ul className={`menu ${menuVisible ? "open" : ""}`}>
        <li>
          <Link to="/home" style={{ color: "white" }}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/webDevelopment" style={{ color: "white" }}>
            Web Development
          </Link>
        </li>
        <li>
          <Link to="/price" style={{ color: "white" }}>
            Pricing Options
          </Link>
        </li>
        <li>
          <Link to="/about" style={{ color: "white" }}>
            About
          </Link>
        </li>
        <li>
          <Link to="/contact" style={{ color: "white" }}>
            Contact
          </Link>
        </li>
        <li>
          <Link to="/blog" style={{ color: "white" }}>
            Blog
          </Link>
        </li>

      </ul>
    </header>
  );
};
export default Header;
