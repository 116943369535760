import { useEffect } from "react";
import "./AboutUs.css";
const AboutUs: React.FC = () => {
  useEffect(() => {
    const imageToShow = document.getElementById("imageToShow");
    const aboutTextArea = document.querySelector(
      ".about_text_area"
    ) as HTMLElement;

    if (imageToShow && aboutTextArea) {
      setTimeout(() => {
        imageToShow.classList.remove("hidden");
        imageToShow.style.opacity = "1";

        aboutTextArea.classList.remove("hidden");
        aboutTextArea.style.opacity = "1";
      }, 10); // 3000 milliseconds = 3 seconds
    }
  }, []);
  return (
    <section id="about_">
      <div className="about_us">
        <div className="wrapper">
          <h1 className="section_sub_title">About Us</h1>
          <div className="about_area">
            <img
              src="./pictures/team.webp"
              alt="developing"
              className={`image-developing hidden`}
              id="imageToShow"
              loading="lazy"
            />
            <div className="about_text_area hidden">
              <p className="about_text">
                Welcome to Lilays, where we are more than just a software
                engineering team - we are a passionate group with a wealth of
                experience in the software industry, dedicated to the craft of
                web development. Located in the vibrant city of Toronto, our
                team brings together a diverse set of skills and a shared
                enthusiasm for creating exceptional online experiences.
              </p>
              <p className="about_text">
                At Lilays, we are not just software engineers, we are your
                dedicated partners in web development. Let us transform your
                online presence, elevate your brand, and propel your business to
                new heights. Contact us today to embark on this exciting journey
                together.
              </p>
            </div>
          </div>
        </div>
        <div className="section_sub_title">Why Choose Us?</div>
        <div className="about_container" style={{ textAlign: "left" }}>
          <div className="wrapper">
            <div className="about">
              <p className="aboutUs_card">
                <span>Development Expertise</span>
                <br />
                Our team of skilled software engineers has extensive experience
                from global corporations. We stay updated with the latest
                technologies and industry practices to bring diverse expertise
                to our projects.
              </p>
              <p className="aboutUs_card">
                <span>Multilingual Communication</span>
                <br />
                Communication is key. We communicate fluently in four languages:
                English, Hebrew, Russian, and Ukrainian. Our multilingual
                ability ensures we understand your needs and can address your
                concerns effectively.
              </p>
              <p className="aboutUs_card">
                <span>End-to-End Web Development</span>
                <br />
                We provide complete web development services, guiding you from
                idea to execution. Whether starting fresh or upgrading your
                site, we've got the expertise to handle the entire process.
              </p>
              <p className="aboutUs_card">
                <span>Cost Consideration</span>
                <br />
                Lilays offers high-quality, budget-friendly web development and
                hosting. Elevate your online presence without breaking the bank.
              </p>

              <p className="aboutUs_card">
                <span>Domain Assistance</span>
                <br />
                Finding a unique domain is crucial for your online identity. Let
                us assist you in choosing a domain that perfectly aligns with
                your brand, making it memorable for your clients.
              </p>
              <p className="aboutUs_card">
                <span>Design Excellence</span>
                <br />
                We're experts at creating attractive and easy-to-use designs. We
                know that a website's look and how well it works are crucial for
                leaving a lasting impression on your visitors.
              </p>
              <p className="aboutUs_card">
                <span>Hosting Management</span>
                <br />
                We make sure your website is hosted by reliable providers,
                ensuring stability, security, and scalability. Let us handle the
                technical details, allowing you to concentrate on growing your
                business.
              </p>
              <p className="aboutUs_card">
                <span>Search Engine Optimization</span>
                <br />
                Improve your online presence with our SEO expertise. We make
                your website rank higher on Google and other search engines,
                attracting more clients.
              </p>

              <p className="aboutUs_card" style={{ color: "white" }}>
                <span>Technical Support</span>
                <br />
                We're committed to your success beyond development. Get ongoing
                technical support for a seamless and stress-free experience
                whenever you need assistance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AboutUs;
