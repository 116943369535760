import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";

import AppRoutes from "./AppRoutes";
import Footer from "./Footer";
import Header from "./Header";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />

        <AppRoutes />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
