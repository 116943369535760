import React from "react";

export const NotFound: React.FC = () => {
  return (
    <div>
      <h2>404 - Not Found</h2>
      <p>The page you're looking for does not exist.</p>
      <p>
        It seems like you've encountered a missing link or entered an incorrect
        URL.
      </p>
      <p>
        Please check the URL or navigate back to the homepage using the
        navigation links above.
      </p>
    </div>
  );
};
