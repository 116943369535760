import Services from "./Services";
import { PiWhatsappLogoLight } from "react-icons/pi";
import "./HomePage.css";
import React, { useState, useEffect, useRef } from "react";
// import { PiWhatsappLogoLight } from "react-icons/pi";
import WhyChooseUs from "./WhyChooseUs";
import VideoBackground from "./VideoBackground";
const HomePage: React.FC = () => {
  const [isImageInViewport, setIsImageInViewport] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust as needed
    };

    const handleIntersection: IntersectionObserverCallback = (
      entries,
      observer
    ) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsImageInViewport(true);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);
  const openWhatsApp = () => {
    // Replace '123456789' with your actual WhatsApp number
    const whatsappNumber = "123456789";
    window.open(`https://wa.me/${whatsappNumber}`, "_blank");
  };
  const openPricingPage = () => {
    // Replace with your pricing page URL
    window.open("/price", "_blank");
  };
  return (
    <section>
      <div className="video_background_area">
        <VideoBackground />
        {/* Button at bottom center */}
        <div className="center_video_button">
          <button className="pricing_button" onClick={openPricingPage}>
            Pricing
          </button>
        </div>
      </div>

      <div
        className="main_section"
        // ref={imageRef}
        // style={{
        //   backgroundImage: isImageInViewport
        //     ? 'url("/pictures/background.webp")'
        //     : "none",
        // }}
      >
        <div className="baner">
          <h1 className="title_baner">
            Web Development, Web Design,
            <br /> Search Engine Optimization
            <br />
            and Hosting Management
          </h1>

          <h2 className="sub_title">
            If you're a small business looking to boost your online presence and
            improve your operations, we've got your back. Your website is a key
            player in making that happen.
            <br />
            <br />
            Our team is here to guide you through the process of designing,
            developing, and managing your website. We're passionate about
            helping your business shine online and stand out in the digital
            world.
            <br />
            <br />
            We communicate in four languages: English, Ukrainian, Hebrew and
            Russian. Our multilingual ability ensures we understand your needs
            and can address your concerns effectively.
          </h2>
          <p className="description"></p>
        </div>
      </div>
      <div className="text_area">
        Grow your business with us - create your special website and host it
        easily for a strong online presence.
      </div>

      <div className="pulse_button">
        <a
          href="https://wa.me/16472255955"
          target="_blank"
          rel="noreferrer"
          aria-label="Chat with Lilays on WhatsApp"
        >
          <PiWhatsappLogoLight className="wppBtn" style={{ color: "green" }} />
        </a>
      </div>
      <Services />

      <WhyChooseUs />
    </section>
  );
};
export default HomePage;
