import "./Footer.css";
import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { CiFacebook } from "react-icons/ci";

import { PiWhatsappLogoLight } from "react-icons/pi";
const Footer: React.FC = () => {
  return (
    <section className="footer">
      <div className="footer_conteiner">
        <div className="footer_sub_container">
          <div className="logo">
            <div className="iconLogo"></div>
            <div className="company_name">Lilays</div>
          </div>

          <p>
            Don't miss the opportunity to establish a strong online presence
            with us here in Toronto.
            <br />
            Contact us today, and let's get started on your digital journey!
            <br />
            <br />
            Looking forward to hearing from you !
          </p>
        </div>
        <div className="contact">
          <div className="contact_info" style={{ color: "#feae43" }}>
            Contact Information:
          </div>
          <br></br>
          Email:
          <a
            href="mailto:info@lilays.com"
            rel="noreferrer"
            target="_blank"
            style={{ color: "white" }}
          >
            info@lilays.com
          </a>
          <br />
          Phone:
          <a style={{ color: "white" }} href="tel:+16472255955">
            +1-647-22-55-9-55
          </a>
          <div className="footer_icons">
            <a
              href="tel:+16472255955"
              aria-label="Call Lilays Customer Service"
              rel="nofollow"
            >
              <CiPhone className="footer_icon" />
            </a>
            <a
              href="mailto:info@lilays.com"
              rel="noreferrer"
              target="_blank"
              aria-label="Email Lilays"
            >
              <CiMail className="footer_icon" />
            </a>
            <a
              href="https://wa.me/16472255955"
              target="_blank"
              rel="noreferrer"
              aria-label="Chat with Lilays on WhatsApp"
            >
              <PiWhatsappLogoLight className="footer_icon" />
            </a>
            <a
              href=" https://www.linkedin.com/company/lilays/"
              target="_blank"
              rel="noreferrer"
              aria-label="Chat with Lilays on WhatsApp"
            >
              <CiLinkedin className="footer_icon" />
            </a>
            {/* <a
              href=" https://www.facebook.com/profile.php?id=61555732112065"
              target="_blank"
              rel="noreferrer"
              aria-label="Chat with Lilays on WhatsApp"
            >
              <CiFacebook className="footer_icon" />
            </a> */}
          </div>
          <br></br>
          <div>© 2023 Lilays. all rights reserved.</div>
        </div>
      </div>
    </section>
  );
};
export default Footer;
