import { PiNumberCircleOneLight } from "react-icons/pi";
import { PiNumberCircleTwoLight } from "react-icons/pi";
import { PiNumberCircleFourLight } from "react-icons/pi";
import { PiNumberCircleThreeLight } from "react-icons/pi";
import { PiNumberCircleFiveLight } from "react-icons/pi";
import { PiNumberCircleSixLight } from "react-icons/pi";
import "./WhyChooseUs.css"

// import { useInView } from 'react-intersection-observer';

const WhyChooseUs: React.FC = () => {
    //     const [ref, inView] = useInView({
    //         triggerOnce: true,
    //         threshold: 0.5, // Adjust this value based on your needs
    //     });

    //     const sectionStyles = {
    //         opacity: inView ? 1 : 0,
    //         transition: 'opacity 0.5s ease-in-out',
    //     };

    return (
        <section >


            <div className="wrapper">


                <h2 className="section_sub_title" style={{ color: "#1273b7" }}> Why choose Lilays ? </h2>
                <div className="whyUs_icon_area">



                    <div className="whyChooseUsCard"><PiNumberCircleOneLight className="whyUs_icon" style={{ color: "orange", fontSize: "80px" }} />   <span>Educated Software Engineers:</span><p>We are educated software engineers with experience in the tech industry, delivering high-quality solutions.</p></div>
                    <div className="whyChooseUsCard"><PiNumberCircleTwoLight className="whyUs_icon" style={{ color: "orange", fontSize: "80px" }} /><span>Expert Website Design: </span>    <p>Captivate your audience with visually stunning and user - friendly websites.</p></div>
                    <div className="whyChooseUsCard"><PiNumberCircleThreeLight className="whyUs_icon" style={{ color: "orange", fontSize: "80px" }} />  <span>Seamless Development: </span><p> Our skilled developers bring your ideas to life with cutting - edge technology.</p></div>
                    <div className="whyChooseUsCard"> <PiNumberCircleFourLight className="whyUs_icon" style={{ color: "orange", fontSize: "80px" }} />  <span>Effective SEO Strategies:</span><p> Boost your online visibility and reach your target audience with our proven SEO techniques.</p></div>
                    <div className="whyChooseUsCard"> <PiNumberCircleFiveLight className="whyUs_icon" style={{ color: "orange", fontSize: "80px" }} /> <span>Reliable Hosting Management:</span>  <p>Ensure your website's performance and security with our top-notch hosting services.</p></div>
                    <div className="whyChooseUsCard"><PiNumberCircleSixLight className="whyUs_icon" style={{ color: "orange", fontSize: "80px" }} /><span>Multilingual Support:</span> <p> We offer multilingual support in English, Ukrainian, Hebrew, and Russian, ensuring clear communication .</p></div>
                    <br />

                </div >
                <p style={{ fontWeight: "500", color: "#1273b7" }}> Ready to take your online presence to new heights ? Partner with Lilays Web Solutions for digital success! </p>
            </div >
        </section >
    )
};
export default WhyChooseUs;
