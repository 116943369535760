import React from "react";
import { useNavigate } from "react-router-dom";
import "./Blog.css";

const Blog: React.FC = () => {
  const navigate = useNavigate();

  const blogData = [
    {
      title: "Mastering Web Accessibility: The Complete Manual",
      description:
        "Website accessibility is the practice of designing and developing websites and web applications that can be used and accessed by people of all abilities and disabilities.",
      link: "/accessibility",
      author: "Elena G.",
      date: " Jan 1, 2024",
      image: "/pictures/web-accessibility.webp",
    },
    {
      title: "Improving Website Speed: A Key Factor in Development",
      description:
        "In the world of web development, making your website load faster is really important. When a website loads quickly, people are more likely to stay and explore.",
      link: "/web_performance",
      author: "Elena G.",
      date: " Feb 22, 2024",
      image: "/pictures/web-performance.webp",
    },
    {
      title: "The Impact of Custom Web Development on Customer Experience",
      description:
        "In today's digital age, a website is often the first point of contact between a business and its customers. It serves as a virtual storefront, offering visitors brand's identity.",
      link: "/custom_web",
      author: "Elena G.",
      date: " Mar 10, 2024",
      image: "/pictures/custom-web.webp",
    },
    {
      title: "Integrating Social Media with Your Website: Enhancing Engagement",
      description:
        "In the dynamic realm of digital presence, integrating social media into your website isn't just advantageous—it's indispensable for enhancing user engagement.",
      link: "/social_media",
      author: "Elena G.",
      date: " Jun 20, 2024",
      image: "/pictures/social-media.webp",
    },
  ];

  const handleButtonClick = (link: string) => {
    navigate(link);
  };

  const generateBlogCards = () => {
    return blogData.map((blog, index) => (
      <div className="blog_card" key={index}>
        <div className="image">
          <img src={blog.image} alt={blog.title} loading="lazy" />
        </div>
        <div className="blog_content">
          <span className="title">{blog.title}</span>
          <p className="desc">{blog.description}</p>
          <div className="author_date_area">
            <span className="author">By {blog.author}, </span>
            <span className="date">{blog.date}</span>
          </div>
          <button
            className="action_button"
            onClick={() => handleButtonClick(blog.link)}
          >
            Find out more <span aria-hidden="true">→</span>
          </button>
        </div>
      </div>
    ));
  };

  return (
    <section>
      <h1 className="blog_title">Stay Informed with Our Blog</h1>
      <div className="wrapper">
        <p>
          Don't miss out on our latest articles and updates! Follow us on social
          media and subscribe to our newsletter to stay informed. <br />
          Join our community of web enthusiasts and stay ahead in the world of
          web development and design!
        </p>
        <p>
          Thank you for visiting our blog. We hope you find our content helpful
          and informative. If you have any questions, suggestions, or topics
          you'd like us to cover, feel free to reach out.
        </p>
        <span className="span_text">Happy reading!</span>
        <div className="blog_container">{generateBlogCards()}</div>
      </div>
    </section>
  );
};

export default Blog;
