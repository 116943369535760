import React from "react";
import "./PriceCard.css";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const PriceCard: React.FC = () => {
  return (
    <section>
      <div className="wrapper">
        <h1 className="section_sub_title">Pricing Options</h1>
        <p className="pricing_area_text">
          We offer you the best price upfront, and there are no surprises upon
          renewal - we are committed to keeping your costs steady
        </p>
        <div className="price_card_container">
          {/* Section 1 */}
          <div className="price_card">
            <div className="header">
              <span className="title">Personalized Website Starting at</span>
              <br />
              <span className="price">499.99 CAD</span>
              <div className="special" style={{ color: "red" }}>
                Special price
              </div>
              <br />
              <p className="text_">
                Order your custom made, unique website using the leading-edge
                methodologies
              </p>
              <br />
              <ul className="lists">
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    Businesses
                  </span>
                </li>
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    Portfolios
                  </span>
                </li>
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    Blogs
                  </span>
                </li>
              </ul>
              <a href="tel:+16472255955">
                <button className="price_card_button">
                  Call Us For Details
                </button>
              </a>
            </div>
          </div>

          <div className="price_card">
            <div className="header">
              <span className="title">WordPress Website Starting at</span>
              <br />
              <span className="price">299.99 CAD</span>
              <div className="special" style={{ color: "red" }}>
                Special price
              </div>
              <br />
              <p className="text_">
                Transform your online presence with a customized WordPress
                website
              </p>
              <br />
              <ul className="lists">
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    Businesses
                  </span>
                </li>
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    Portfolios
                  </span>
                </li>
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    Blogs
                  </span>
                </li>
              </ul>
              <a href="tel:+16472255955">
                <button className="price_card_button">
                  Call Us For Details
                </button>
              </a>
            </div>
            {/* </div> */}
          </div>
          {/* Section 3 */}
          <div className="price_card">
            <div className="header">
              <span className="title">Website Hosting Management</span>
              <br />
              <span className="price">3.99 CAD</span>

              <div className="special"> Per month</div>
              <br />
              <p className="text_">Leave the headache and management to us</p>
              <br />
              <ul className="lists">
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    5 GB SSD storage
                  </span>
                </li>
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    Deployment to a machine
                  </span>
                </li>
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    SSL Certificate registration
                  </span>
                </li>
              </ul>
              <a href="tel:+16472255955">
                <button className="price_card_button">
                  Call Us For Details
                </button>
              </a>
            </div>
          </div>
          {/* Section 2 */}
          <div className="price_card">
            <div className="header">
              <span className="title">Website Migration</span>
              <br />
              <span className="price">0 CAD</span>
              <div className="special"> Free migration</div>
              <br />
              <p className="text_">Leave the headache and management to us</p>
              <br />
              <ul className="lists">
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    Secured
                  </span>
                </li>
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    Fast
                  </span>
                </li>
                <li className="list">
                  <span>
                    <IoMdCheckmarkCircleOutline
                      style={{ color: "#feae43", paddingInline: "10px" }}
                    />
                    Zero downtime
                  </span>
                </li>
              </ul>
              <a href="tel:+16472255955">
                <button className="price_card_button">
                  Call Us For Details
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PriceCard;
