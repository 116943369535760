import { CgWebsite } from "react-icons/cg";
import { MdOutlineWebhook } from "react-icons/md";
import { AiOutlineProject } from "react-icons/ai";
import "./WebDev.css";
import Portfolio from "./Portfolio";

const WebDev: React.FC = () => {
  return (
    <section className="third_section">
      <div className="wrapper">
        <Portfolio />
        <h1 className="section_sub_title">
          Custom Web Development, Redesign <br /> and Template-Based Solutions
        </h1>
        <div className="web_container">
          <div className="web_image">
            <CgWebsite className="web_icon" />
            <h2 className="web_container_title">Custom Web Solutions</h2>
          </div>
          <p>
            Unleash the power of a unique online identity with our custom web
            development services. Our team of seasoned professionals is
            dedicated to understanding your business objectives and translating
            them into a visually stunning and highly functional website. From
            design concept to implementation, we ensure a seamless and
            personalized experience.
          </p>
        </div>
        <div className="web_container_middle">
          <div className="web_image">
            <MdOutlineWebhook className="web_icon" />
            <h2 className="web_container_title">Website Redesign</h2>
          </div>
          <p>
            Is your existing website in need of a facelift? We can redesign and
            give new life into outdated platforms, optimizing user experience,
            and incorporating the latest design trends. We analyze your current
            website, identify areas for improvement, and execute a strategic
            redesign to enhance both aesthetics and functionality.
          </p>
        </div>
        <div className="web_container">
          <div className="web_image">
            <AiOutlineProject className="web_icon" />
            <h2 className="web_container_title">Template-Based Solutions</h2>
          </div>
          <p>
            For those seeking a cost-effective yet professional solution,
            explore our curated selection of templates. We offer a variety of
            modern, responsive designs that can be easily customized to align
            with your brand. Choose a template that resonates with your vision,
            and let us tailor it to meet your specific requirements.
          </p>
        </div>
      </div>
    </section>
  );
};
export default WebDev;
