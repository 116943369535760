

import ShareButtons from './ShareButtons';
import "./AccessibilityBlog.css";
export const downloadChecklist = () => {
    const checklistUrl = '/pictures/Quick Web Accessibility Checklist.pdf';
    const link = document.createElement('a');
    link.href = checklistUrl;
    link.setAttribute('download', 'Quick Web Accessibility Checklist.pdf');
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
    }, 100);
};

const AccessibilityBlog: React.FC = () => {


    const imageString = '<img src="example-image.jpg" alt="A diverse group of people collaborating in a modern office space, discussing ideas and working together with laptops and notepads on a conference table.">';
    const videoString = `<video controls>
    <source src="example-video.mp4" type="video/mp4">
    <track kind="captions" label="English" src="captions-en.vtt" srclang="en" default>
    Your browser does not support the video tag.
  </video>`;
    const audioString = `<audio controls>
    <source src="example-audio.mp3" type="audio/mp3">
    <track kind="subtitles" label="Transcription" src="transcription-en.vtt" srclang="en" default>
    Your browser does not support the audio tag.
</audio>`;
    const semanticHTMLExample = `
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Semantic HTML Example</title>
    <link rel="stylesheet" href="styles.css">
</head>

<body>
    <header>
        <h1>My Website</h1>
        <nav>
            <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
        </nav>
    </header>

    <main>
        <section id="home">
            <h2>Welcome to My Website</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget nisi nec velit feugiat tempus. Donec nec urna sit amet erat efficitur eleifend.</p>
        </section>

        <section id="about">
            <h2>About Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget nisi nec velit feugiat tempus. Donec nec urna sit amet erat efficitur eleifend.</p>
        </section>

        <section id="services">
            <h2>Our Services</h2>
            <ul>
                <li>Service 1</li>
                <li>Service 2</li>
                <li>Service 3</li>
            </ul>
        </section>

        <section id="contact">
            <h2>Contact Us</h2>
            <p>Email: info@example.com</p>
            <p>Phone: 123-456-7890</p>
        </section>
    </main>

    <footer>
        <p>&copy; 2024 My Website. All rights reserved.</p>
    </footer>
</body>

</html>
`;


    return (
        <section>

            <div className="wrapper">
                <div className="blog_image_container">
                    <img className="web_accessibility_image" src="/pictures/web-accessibility.webp" alt="web accessibility" loading="lazy"></img>
                    <div className="text-container">
                        <span className='blog_share'>Share : </span>
                        <ShareButtons />
                        <h1 className="blog_title">Mastering Web Accessibility:<br></br> The Complete Manual</h1>
                    </div>
                </div>
                <div className="blog_text_area">

                    <p>Explore the importance of web accessibility, learning why it's crucial to invest in making your website usable for everyone, and how doing so can strengthen the bond between your brand and its customers, ultimately increasing loyalty to your brand.</p>
                    <p>Website accessibility, also known as web accessibility, is the practice of designing and developing websites and web applications that can be used and accessed by people of all abilities and disabilities. The goal is to create an inclusive digital environment in which everyone, regardless of their physical or cognitive abilities, can perceive, understand, manage and effectively interact with online content.</p>

                    <span className="blog_span">Understanding Web Accessibility</span>
                    <p>Website accessibility is the practice of making websites usable by people with disabilities, impairments, or limitations. This involves adhering to design principles that ensure a level playing field for all users, regardless of ability.</p>
                    <span className="blog_span">Addressing a Spectrum of Disabilities on Your Website</span>
                    <p>When striving to make a website accessible, it is important to consider a number of limitations and violations to ensure inclusivity. Here are some key disability categories to look out for:</p>
                    <span className="blog_sub_title">Visual impairment: </span>
                    <p><span className="category">Blindness: </span>Users who are completely blind and rely on screen readers.</p>
                    <p><span className="category"> Low Vision: </span>Users with partial vision impairment who can use a screen magnifier or need high contrast.</p>
                    <span className="blog_sub_title">Hearing impairment: </span>
                    <p> <span className="category">Deafness: </span>Users who are completely deaf and may rely on visual content or subtitles.</p>
                    <p><span className="category"> Hearing loss: </span>Users with partial hearing loss who may benefit from closed captioning or transcription. </p>
                    <span className="blog_sub_title"> Movement disorders:</span>
                    <p><span className="category">Mobility impairments: </span> Users with limited or no physical mobility who may be able to use alternative input devices or keyboard navigation.</p>
                    <p><span className="category"> Dexterity Issues: </span>Users who have difficulty using a mouse or making precise movements may rely on keyboard navigation.</p>
                    <span className="blog_sub_title"> Cognitive impairment:</span>
                    <p> <span className="category"> Learning Disability: </span>Users with learning, comprehension, or memory impairments.</p>
                    <p><span className="category"> Cognitive impairment: </span>Users who have difficulty processing information or making decisions.</p>
                    <p> <span className="category"> Speech disorders: </span>Users who may have difficulty communicating verbally.</p>
                    <p>It is essential to provide design flexibility to meet a wide range of needs. </p>
                    <span className="blog_sub_title">Some additional considerations include:</span>
                    <p>Epilepsy and seizure disorders. Avoid content that may cause seizures, such as flashing lights.</p>
                    <p><span className="category">Light Sensitivity: </span>Some users may be sensitive to bright or rapidly changing colors.</p>
                    <p>Multiple violations. Some users may have a combination of impairments, so the website must be tailored to suit different needs.
                        By keeping these considerations in mind, you can create a website that is more accessible and convenient for a diverse audience. Additionally, following the <a href="https://www.w3.org/WAI/standards-guidelines/wcag/"> Web Content Accessibility Guidelines (WCAG)</a> provides specific criteria and recommendations for addressing these various deficiencies. Regular testing and user feedback is also essential to continually improve and improve the accessibility of your website.
                    </p>
                    <span className="blog_sub_title">Website accessibility control </span>
                    <p>The World Wide Web Consortium (W3C) Web Accessibility Initiative (WAI) is responsible for global Internet accessibility initiatives. They publish the  <a href="https://www.w3.org/TR/WCAG21/" target="_blank" rel="noreferrer">Web Content Accessibility Guidelines (WCAG)</a> , which offer standards that designers and developers should follow when creating accessible websites.</p>
                    <span className="blog_sub_title">The Importance of Web Accessibility</span>
                    <p>Online accessibility is not just an option; it's a necessity. By prioritizing accessibility, you improve the user experience for all visitors while demonstrating a commitment to inclusivity. This not only increases brand loyalty, but also helps avoid potential legal problems.</p>
                    <span className="blog_sub_title">Legal Considerations </span>
                    <p>While there is no universal law mandating web accessibility, businesses, especially those with physical stores and websites, may face legal consequences. Accessibility-related lawsuits have increased, with courts applying the Americans with Disabilities Act (ADA) to websites as “public accommodations.”</p>
                    <span className="blog_sub_title">Key Principles of Web Accessibility</span>
                    <span className="blog_sub_title">WCAG outlines four core principles for creating accessible websites: </span>
                    <p><span className="category">Understandability: </span>Ensure that content is presented in a way that can be understood by all users, regardless of ability, including those who rely on screen readers.</p>
                    <p><span className="category">Accessibility: </span>Create websites that are easy to navigate and work with, simplifying functionality for users with disabilities.</p>
                    <p><span className="category">Understandability: </span>Make content, language, and navigation understandable to all users, meeting the needs of people with cognitive difficulties.</p>
                    <p><span className="ctaregory">Reliability: </span>Make sure your website code is easily interpretable using assistive technologies, making it accessible to a diverse audience.</p>

                    <span className="blog_sub_title">To improve your site's accessibility:</span>

                    <p>Conduct audits. Regularly evaluate your website's accessibility using the tools and guidelines provided by WCAG.</p>

                    <p>Prioritize keyboard navigation: Ensure that all functions are accessible only using the keyboard, which helps users with limited mobility.</p>

                    <p> Provide alt text: Include descriptive alt text for images to help users with visual impairments.</p>
                    <span className="category">Image Text Example:</span><br></br>

                    <pre>{imageString}</pre>
                    <span className="blog_sub_title">Captioning and Media Transcription:</span> <p>Make audio and video content accessible by providing captioning and transcription.</p>
                    <p>Adding captions to videos and providing transcriptions for audio content is essential for making your multimedia accessible to users with hearing impairments or those who may prefer reading over listening. Here's an example for both video captioning and audio transcription:</p>
                    <span className="category">Video Captioning Example:</span><br></br>
                    <pre>{videoString}</pre>
                    <br></br>
                    <span className="category">In this example: </span >
                    <p>

                        Controls: Adds playback controls to the video player.<br></br>
                        source: Specifies the source of the video file (replace "example-video.mp4" with the actual file name or URL).<br></br>
                        track: adds a subtitle track.<br></br>
                        kind="captions": Indicates that this track contains subtitles.<br></br>
                        label="English": describes the language of the captions.<br></br>
                        src="captions-en.vtt": Specifies the file source for the captions (replace with the actual file name or URL).<br></br>
                        srclang="en": Defines the language of the subtitle track.<br></br>

                    </p>

                    <span className="category">Audio transcription example: </span><br></br>
                    <pre>{audioString}</pre><br></br>
                    <span className="category">In this example:</span>
                    <p>controls: Adds playback controls to the audio player.<br></br>
                        source: Specifies the audio file source (replace "example-audio.mp3" with the actual file name or URL).<br></br>
                        track: Adds a subtitles track for transcription.<br></br>
                        kind="subtitles": Specifies that this track contains subtitles (transcription).<br></br>
                        label="Transcription": Describes the purpose of the subtitles.<br></br>
                        src="transcription-en.vtt": Specifies the file source for the transcription (replace with the actual file name or URL).<br></br>
                        srclang="en": Defines the language of the transcription.<br></br>
                        Remember to replace placeholder values with actual file names or URLs and customize the language and labels based on your content.<br></br>
                    </p>
                    <p><span className="category">Use semantic HTML: </span> Structure your website using semantic HTML, helping it to be interpreted correctly using assistive technologies.</p>
                    {/* <img src="/pictures/sematic-HTML-example.png" alt="sematic HTML code example"></img> */}
                    <pre>{semanticHTMLExample}</pre>

                    <p>By adhering to these principles and guidelines, you contribute to creating a more inclusive digital environment by providing a positive user experience for all visitors to your website.</p>
                    <span className="category">
                        In this example:</span>
                    <p>
                        Semantic HTML elements like &lt;header&gt;, &lt;nav&gt;, &lt;main&gt;, &lt;section&gt;  and &lt;footer&gt; are used to structure the content appropriately.<br></br>
                        The &lt;header&gt; element contains the main heading of the page and a navigation menu.<br></br>
                        The &lt;nav&gt; element contains a list of navigation links.<br></br>
                        The &lt;main&gt; element contains the main content of the page, divided into sections using &lt;section&gt; elements.<br></br>
                        Each section has a heading (&lt;h2&gt;) to indicate its purpose and content.<br></br>
                        The &lt;footer&gt; element contains copyright information.<br></br>
                        By using semantic HTML, assistive technologies can better understand the structure of the page, making it more accessible to users with disabilities.
                    </p>
                    <span className="blog_sub_title">Download Accessibility Checklist</span>
                    <p>Download our comprehensive accessibility checklist to ensure your website meets the highest standards of accessibility</p>
                    <button className="download_button"
                        onClick={downloadChecklist}>Download Checklist</button>
                </div>
            </div>

        </section >
    );
}
export default AccessibilityBlog;


