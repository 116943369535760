import React from "react";
import { Route, Routes } from "react-router-dom";

import AboutUs from "./AboutUs";
import HomePage from "./HomePage";
import WebDev from "./WebDev";
import PriceCard from "./PriceCard";
import ContactUs from "./ContactUs";
import { NotFound } from "./NotFound";
import AccessibilityBlog from "./AccessibilityBlog";
import Blog from "./Blog";
import WebPerformanceBlog from "./WebPerformaneBlog";
import CustomWebBlog from "./CustomWebBlog";
import SocialMediaBlog from "./SocialMediaBlog";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/webDevelopment" element={<WebDev />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/price" element={<PriceCard />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/accessibility" element={<AccessibilityBlog />} />
      <Route path="/web_performance" element={<WebPerformanceBlog />} />
      <Route path="/custom_web" element={<CustomWebBlog />} />
      <Route path="/social_media" element={<SocialMediaBlog />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
